<template lang="html">
  <section class="login">
    <div class="container login column-center">
      <div class="contenedor-login">
        <h1 class="titulo-formulario-login">
          Iniciar Sesi<span class="tilde-Manhattan">o</span>n
        </h1>
        <Form :validation-schema="schema" v-slot="{ errors }">
          <div>
            <Field
              name="email"
              class="input-styled"
              placeholder="Email"
              :class="{ error: errors.email }"
              v-model="data.email"
            />
            <span class="mss-error">{{ errors.email }}</span>
          </div>

          <div class="column contenedor-pass">
            <Field
              name="password"
              class="input-styled"
              placeholder="Contraseña"
              :type="showPassword ? 'text' : 'password'"
              :class="{ error: errors.password_confirmation }"
              v-model="data.password"
            />
            <span class="mss-error">{{ errors.password }}</span>
            <a>
              <i @click="showPassword=!showPassword" :class="[this.showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa']"></i>
            </a>
          </div>

          <div class="contenedor-info-login">
            <div class="checkbox-styled">
              <Field
                id="remember_me"
                name="remember_me"
                type="checkbox"
                v-model="data.remember_me"
                :value="true"
                :unchecked-value="false"
              />
              <label for="remember_me">Recuérdame</label>
            </div> 

            <div>
              <p class="texto-login" @click="rememberPassword = true">
                <router-link :to="{ name: 'rememberPassword'}">
                  ¿Has olvidado tu contraseña?</router-link
                >
              </p>
            </div>

            <div class="">
              <div @click="onLogin" class="boton-styled">Entrar</div>
            </div>

            <div class="texto-login background column-center">
              <p class="reg-bold">
                ¿Es tu primera vez en la Zona de Profesionales Santiveri?
              </p>
              <p class="reg-bold"><a href="/registro">Regístrate AQUÍ</a></p>
            </div>
          </div>
        </Form>
      </div>
    </div>
   
  </section>
</template>

<script lang="js">
import { Form, Field} from "vee-validate";
import { mapGetters, mapActions } from 'vuex';

  export default  {
    name: 'login',
    props: [],
    data () {
      const schema = {
        email: 'required|email',
        password: 'required|min:8',
      };
      return {
        schema,
        showPassword : false,
         data : {
           email: '',
           password:'',
           remember_me: false,
         }
      };
    },
    components: {
      Field,
      Form
    },
    methods: {
      ...mapActions({
        login : 'auth/login'
      }),
      onLogin(){
        this.login(this.data).then(r => {
          let name = sessionStorage.getItem('originalRoute') ? JSON.parse(sessionStorage.getItem('originalRoute')).name : 'home';
          sessionStorage.removeItem('originalRoute')
          this.$router.push({ name: name});
        })
      }
    },
    computed: {
      ...mapGetters({
        user: "auth/getUser",
      }),
    }
}
</script>

<style scoped lang="scss">
.texto-login u,
.volver {
  cursor: pointer;
}
.container.categorias {
  padding-top: 0;
  padding-bottom: 0;
}
.mss-error {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 285px;
  color: #d32626;
}
.contenedor-login .input-styled {
  margin-bottom: 0;
}
</style>
